<template>
  <div>
    <div
      class="event-detail"
      v-if="EventDetailData && Object.keys(EventDetailData).length"
    >
      <div class="content">
        <div class="event-detail__title" v-html="EventDetailData.name"></div>
      </div>
      <Breadcrumbs
        :pages="[
          {
            name: 'Мероприятия',
            link: {
              name: 'Events',
            },
          },
          {
            name: EventDetailData.name,
          },
        ]"
      />
      <div class="content first-banner__content">
        <div class="first-banner">
          <picture>
            <source
              :srcset="require('@/assets/img/prorak2/banner.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/prorak2/banner.jpg')"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="require('@/assets/img/prorak2/banner.jpg')"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="require('@/assets/img/prorak2/banner.jpg')"
            />
          </picture>
        </div>
      </div>
      <div class="content">
        <div class="event-detail__timer mt-8">
          <div class="event-detail__timer-title">
            {{
              !Object.keys(timerResult).length
                ? "Мероприятие началось"
                : timerResult.months
                ? "Подключение к трансляции:"
                : "Мероприятие начнется через:"
            }}
          </div>
          <div class="event-detail__timer-date" v-if="timerResult.months">
            {{ $m(EventDetailData.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetailData.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div>
          <div
            v-else-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <!-- <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div> -->
            <div class="event-detail__timer-item" v-if="timerResult.days > 0">
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                  getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
           <a
                :href="$root.ssoLinks.login"
                v-if="!Object.keys(timerResult).length && (!user || !Object.keys(user).length)"
                class="event-detail__translation button button_white-blue px-6"
              >
                Зарегистрироваться
              </a>
          <div
            v-else-if="!Object.keys(timerResult).length && (user && Object.keys(user).length)"
            @click="openIframe()"
            class="event-detail__translation button button_white-blue px-6"
          >
            Смотреть
          </div>
        </div>
        <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-item" v-if="EventDetailData.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                {{ $m(EventDetailData.date).format("DD MMMM") }},
                {{
                  $m
                    .utc(EventDetailData.date)
                    .utcOffset(+3)
                    .format("HH:mm")
                }}
                (Мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetailData.place">
              <div class="event-detail__date-item-title">Место</div>
              <div class="event-detail__date-item-value">
                {{ EventDetailData.place }}
              </div>
            </div>
            <div
              class="event-detail__date-item ml-auto ml-sm-0 ml-md-0 mr-0"
             
            >
             <a
                :href="$root.ssoLinks.login"
                v-if="Object.keys(timerResult).length && (!user || !Object.keys(user).length)"
                class="event-detail__translation button button_dark-blue px-6"
              >
                Зарегистрироваться
              </a>
            </div>
          </div>
        </div>
        <div class="event-detail__description-block">
          <div class="_mr-8">
            <div class="event-detail__about-title">О мероприятии</div>
            <div class="event-detail__about-description">
              Время менять форматы! Встречайте новый прямой эфир
              образовательной серии PRОКОНСИЛИУМ на платформе PRОРАК ЛЕГКОГО, в
              рамках которого мы
              <br />
              <br />
              • проведем блиц-опрос по новостям резектабельного 
              немелкоклеточного рака легкого (НМРЛ) вместе с ведущими
              специалистами в этой области<br />
              <br />
              • подойдем к обсуждению клинических случаев в совершенно новой
              интерпретации <br />
              <br />
              27 ноября 12:00 (МСК) ждем Вас в нашем эфире!
            </div>
          </div>
          <!-- <picture>
            <source
              :srcset="require('@/assets/img/breztri/medication_mob.png')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/breztri/medication_tab.png')"
              media="(max-width: 1220px)"
            />
            <source
              :srcset="require('@/assets/img/breztri/medication.png')"
              media="(min-width: 1221px)"
            />
            <img :src="require('@/assets/img/breztri/medication.png')" />
          </picture> -->
        </div>

        <DefaultSlider
          :color="'#2186AF'"
          :title="'Наши эксперты'"
          :settings="settingsSlider"
          id="experts"
        >
          <template #items>
            <div
              class="slide-lector"
              v-for="(lector, ind) in lectors"
              :key="ind"
            >
              <div class="slide-lector__image-container">
                <img class="slide-lector__image" :src="lector.image" />
              </div>
              <div class="slide-lector__tag" v-html="lector.tag"></div>
              <div class="slide-lector__name" v-html="lector.name"></div>
              <div
                class="slide-lector__description"
                v-html="lector.description"
              ></div>
            </div>
          </template>
        </DefaultSlider>

        <div class="event-detail__about-title">Расписание и лекторы</div>
        <InfoDrop
          @openInner="openInner"
          v-for="(item, ind) in program"
          :key="ind"
          :active="true"
          :item="{
            title: item.title,
            content: galaxyProgramm(item.program),
          }"
        />
        <div class="event-detail__usefull-materials">
          <div class="event-detail__usefull-materials-title">
            Полезные материалы
          </div>
          <div class="event-detail__usefull-materials-description">
            В период ожидания эфира, мы предлагаем вам ознакомиться с
            материалами
          </div>
          <div class="event-detail__usefull-materials-row">
            <div class="event-detail__usefull-materials-item">
              <div class="event-detail__usefull-materials-item-title">
                Платформа для специалистов здравоохранения PRОРАК ЛЕГКОГО
              </div>
              <a
                href="https://storage.yandexcloud.net/az-most.ru-storage/media/content/prorak2/PROPAK_LEAFLET_A5_%D0%BE%D0%B4%D0%BE%D0%B1%D1%80%D0%B5%D0%BD.pdf"
                target="_blank"
                class="
                  event-detail__usefull-materials-item-button
                  button button_pink button_small
                "
                >Скачать PDF</a
              >
            </div>
            <!-- <div class="event-detail__usefull-materials-item">
              <div class="event-detail__usefull-materials-item-title">
                Раскрывая ценность POSEIDON
              </div>
              <a
                href="https://storage.yandexcloud.net/az-most.ru-storage/media/content/prorak2/POSEIDON%20%D0%B1%D1%83%D0%BA%D0%BB%D0%B5%D1%82.pdf"
                target="_blank"
                class="
                  event-detail__usefull-materials-item-button
                  button button_pink button_small
                "
                >Скачать PDF</a
              >
            </div> -->
            <!-- <div class="event-detail__usefull-materials-item">
              <div class="event-detail__usefull-materials-item-title">
                Новые горизонты CASPIAN
              </div>
              <a
                href="http://storage.yandexcloud.net/az-most.ru-storage/media/content/prorak2/%D0%91%D1%83%D0%BA%D0%BB%D0%B5%D1%82%20CASPAIN_2024.pdf"
                target="_blank"
                class="
                  event-detail__usefull-materials-item-button
                  button button_pink button_small
                "
                >Скачать PDF</a
              >
            </div> -->
          </div>
        </div>
        <div class="event-detail__usefull-materials">
          <div class="event-detail__usefull-materials-title">
            Список сокращений
          </div>
          <div class="event-detail__usefull-materials-description">
            НМРЛ – немелкоклеточный рак легкого;
            <br />
            <br />
            EGFR - рецептор эпидермального фактора роста;
            
          </div>
        </div>
        <div
          class="event-detail__iframe mb-8"
          v-if="!Object.keys(timerResult).length"
          :class="{ 'event-detail__iframe_opened': openFrame }"
        >
          <div
            @click="openFrame = false"
            v-if="openFrame"
            class="event-detail__close-btn"
          >
            <span class="button button_pink button_small">Назад</span>
          </div>

          <iframe
            v-if="
              IframeAccess &&
              IframeAccess[`event_${EventDetailData.id}`] &&
              Object.keys(IframeAccess[`event_${EventDetailData.id}`]).length
            "
            class="d-xl-block"
            id="userplayer"
            frameborder="0"
            :src="
              IframeAccess[`event_${EventDetailData.id}`].url +
              '&lang=Ru-ru&disableSupport=1'
            "
            allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
            allowusermedia
          ></iframe>
          <iframe
            v-else
            class="d-xl-block"
            id="userplayer"
            frameborder="0"
            :src="`https://edu.livedigital.space/room/${
              EventDetailData.webinar_id
            }/?participantName=${
              $route.query.email || ''
            }&lang=Ru-ru&disableSupport=1`"
            allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
            allowusermedia
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import DefaultSlider from "@/components/pageComponents/DefaultSlider.vue";

export default {
  metaInfo() {
    if (this.EventDetailData.name) {
      return {
        title: "Az-most - " + this.EventDetailData.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetailData.name };
    }
  },
  name: "EventDetail",
  components: { Breadcrumbs, InfoDrop, DefaultSlider },
  data: () => ({
    popup: false,
    openFrame: false,
    timerResult: { id: 0 },
    timerResultEnd: {},
    interval: null,
    settingsSlider: {
      slidesToShow: 4,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: true,
            variableWidth: false,
          },
        },
      ],
    },
  }),
  computed: {
    ...mapGetters(["EventDetail", "user", "IframeAccess"]),
    lectors() {
      return [
        {
          image: require("@/assets/img/prorak2/barmin.jpg"),
          tag: "Спикер",
          name: "Бармин Виталий Валерьевич",
          description:
            "к.м.н., торакальный хирург-онколог, руководитель Центра патологии средостения и миастении, заведующий группой миастении МНИОИ им. П.А. Герцена – филиала «НМИЦ радиологии» Минздрава России, г. Москва",
        },
        {
          image: require("@/assets/img/prorak2/karnauhov.jpg"),
          tag: "Спикер",
          name: "Карнаухов Николай Сергеевич",
          description:
            "к.м.н., заведующий патологоанатомическим отделением ГБУЗ «МКНЦ им.А.С. Логинова», г. Москва",
        },
        {
          image: require("@/assets/img/prorak2/danilova.jpg"),
          tag: "Спикер",
          name: "Данилова Анастасия Сергеевна",
          description:
            "врач-химиотерапевт, заведующая отделением ГБУЗ МГОБ №62, г. Москва",
        },
      ];
    },
    EventDetailData() {
      return {
        id: 662,
        lector: [],
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
        ],
        nosology: [
          {
            id: 3,
            title: "Кардиология",
            slug: "cardiology",
            icon: "/media/uploads/nosology/icons/2022/07/06/cardiology-icon.svg",
            active: true,
          },
        ],
        name: "PRОКОНСИЛИУМ – НОВАЯ СЕРИЯ ПРЯМЫХ ЭФИРОВ ПО РАКУ ЛЕГКОГО",

        date: "2024-11-27T12:00:30+03:00",
        date_end: "2024-11-27T12:50:31+03:00",
        place: "Онлайн",
        webinar_id: "2NsI7cKAtO",
        room_id: "6745ded1c3c2244f37be1829",
      };
    },
    program() {
      return [
        {
          title: "<b>PRОТРЕНДЫ:</b> что нового в резектабельном раке легкого?",
          program: [
            {
              time: "12:00 – 12:30",
              title: "Данилова Анастасия Сергеевна",
              icon: "danilova",
              description: `
              врач-химиотерапевт, заведующая отделением ГБУЗ МГОБ №62, г. Москва`,
            },
            {
              time: "",
              title: "Бармин Виталий Валерьевич",
              icon: "barmin",
              description: `
              к.м.н., торакальный хирург-онколог, руководитель Центра патологии средостения и миастении, заведующий группой миастении МНИОИ им. П.А. Герцена – филиала «НМИЦ радиологии» Минздрава России, г. Москва`,
            },
            {
              time: "",
              title: "Карнаухов Николай Сергеевич",
              icon: "karnauhov",
              description: `
              к.м.н., заведующий патологоанатомическим отделением ГБУЗ «МКНЦ им.А.С. Логинова», г. Москва`,
            },
          ],
        },
        {
          title:
            "<b>PROКОНСИЛИУМ</b>: обсуждаем подходы к терапии резектабельного EGFR+ НМРЛ в реальной практике",
          program: [
            {
              time: "12:30 – 12:45",
              title: "Магамедова Марьям Магомедовна",
              icon: "person",
              description: `
              врач-химиотерапевт ГБУ РД «Республиканский онкологический центр», г. Махачкала`,
            },
            {
              time: "",
              title: "Ахмедов Анатолий Дмитриевич",
              icon: "person",
              description: `
              торакальный хирург-онколог ГБУЗ «Новосибирский областной клинический онкологический диспансер», г. Новосибирск`,
            },
          ],
        },
        {
          title: "Подведение итогов",
          program: [
            {
              time: "12:45 – 12:50",
              title: "Данилова Анастасия Сергеевна",
              icon: "danilova",
              description: `
              врач-химиотерапевт, заведующая отделением ГБУЗ МГОБ №62, г. Москва`,
            },
            {
              time: "",
              title: "Бармин Виталий Валерьевич",
              icon: "barmin",
              description: `
              к.м.н., торакальный хирург-онколог, руководитель Центра патологии средостения и миастении, заведующий группой миастении МНИОИ им. П.А. Герцена – филиала «НМИЦ радиологии» Минздрава России, г. Москва`,
            },
            {
              time: "",
              title: "Карнаухов Николай Сергеевич",
              icon: "karnauhov",
              description: `
              к.м.н., заведующий патологоанатомическим отделением ГБУЗ «МКНЦ им.А.С. Логинова», г. Москва`,
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions(["fetchEventDetail", "fetchIframeAccess"]),
    galaxyProgramm(items) {
      let html = ``;
      let block = ``;
      for (let item of items) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time" ${
                  item.time ? "" : 'style="opacity: 0'
                }">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-2" src="${require(`@/assets/img/prorak2/${
                    item.icon || "person"
                  }.svg`)}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetailData.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    openInner() {
      // if (typeof ym !== "undefined") {
      //   ym(91468266, "reachGoal", "see broadcast programm click", {
      //     "see broadcast programm click": {
      //       ...this.$root.ymFields,
      //     },
      //   });
      // }
    },
    closePopup() {
      this.popup = false;
    },
     openIframe() {
      this.openFrame = true;
    },
  },
  created() {
     this.timer();
     this.interval = setInterval(this.timer, 1000);
  },
  async mounted() {
    await this.fetchEventDetail({ id: this.EventDetailData.id });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        this.fetchIframeAccess(this.EventDetailData.id);
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.first-banner {
  overflow: hidden;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1220px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }
  &__content {
    margin-top: 40px;
  }

  &__title {
    display: none;
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 24px;

    @media screen and (min-width: 1220px) {
      color: #fff;
    }

    span {
      @media screen and (min-width: 1220px) {
        color: #ff7f32;
      }
    }

    @media screen and (max-width: 767px) {
      order: 2;
      display: block;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 24px;
      margin-top: 24px;
      span {
        @media screen and (min-width: 1220px) {
          color: #ff7f32;
        }
        // font-size: 21px;
        // line-height: 26px;
      }
    }
  }

  &__button {
    width: 200px;
    margin-top: auto;
    @media screen and (max-width: 767px) {
      order: 1;
      width: 100%;
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 16px;
    color: #3c4242;
    @media screen and (min-width: 1220px) {
      color: #fff;
    }
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 592px;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      position: static;
      height: auto;
      margin-top: 16px;
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      // background-color: #ccd7e0;
      padding: 24px 16px;
      display: flex;
      margin-top: 0;
      flex-direction: column;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    background-image: url("~@/assets/img/prorak2/timer.jpg");
    background-size: cover;
    background-position: bottom right;
    color: #fff;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      margin-right: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 1220px) {
        margin-right: auto;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-right: auto;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1220px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1220px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  & iframe {
    margin-bottom: 48px;
    width: 100%;
    height: 800px;

    @media screen and (max-width: 1220px) {
      width: 100%;
      height: 600px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      height: 400px;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border: 1px solid #ebefee;
    border-left: 4px solid #01d1ff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    margin-top: 64px;
    margin-bottom: 40px;
    background-color: #fff;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__additional {
    margin-top: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__date-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }

  &__usefull-materials {
    margin-top: 48px;
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-weight: 400;
      line-height: 29px;
      color: #1f1f1f;
      margin-bottom: 16px;
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #3c4242;
      margin-bottom: 24px;
    }

    &-row {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 16px;

      @media screen and (max-width: 1220px) {
        overflow-x: auto;
      }
    }

    &-item {
      padding: 16px;
      border: 1px solid #d2d2d2;
      display: flex;
      flex-direction: column;

      &-theme {
        margin-bottom: 8px;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #830051;
      }

      &-title {
        margin-bottom: 16px;
        font-family: "Roboto Slab", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #3c4242;
      }

      &-button {
        margin-top: auto;
      }
    }
  }
}

.slide-lector {
  // padding: 24px;
  border-radius: 16px;
  height: 100%;

  &__image-container {
    width: 280px;
    height: 270px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__tag {
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: #132549;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    width: fit-content;
  }

  &__name {
    margin-bottom: 8px;
    color: #3c4242;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    color: #b2b4b4;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.default-slider {
  padding: 24px 0 48px;
}
</style>

<style lang="scss">
.hobl {
  &__program {
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // background: rgba(0, 0, 0, 0.8);
      // padding: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #132647;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #f8f8f8;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 65%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #656969;
    }
  }

  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
      }
    }

    &__button {
      width: 100%;
      border: 1px solid #2186af;
      color: #2186af;
      background-color: transparent;

      &:hover {
        border-color: #2eb5eb;
        color: #2eb5eb;
      }
    }
  }
}
.event-detail {
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>
